if(document.querySelector('.info-panel') != null) {
  const modal = document.querySelector('.modal-video') as HTMLDialogElement
  const video = document.querySelector('.modal-video__video') as HTMLVideoElement
  const playButton = document.querySelector('.info-panel__button') as HTMLElement
  const videoPlaceholder = document.querySelector('.info-panel__right') as HTMLElement

  playButton.addEventListener('click', ()=>{
    modal?.showModal()
  })

  videoPlaceholder.addEventListener('click', ()=>{
    modal?.showModal()
  })

  window.addEventListener('click', function(event) {
    if (video && (event.target as Element).className == "modal-video") {
      closeVideoModal()
    }

    if(video && (event.target as Element).id == "modal-video__close")   {
      closeVideoModal()
    }

    if((event.target as Element).id == "modal-video__close-icon"){
          closeVideoModal()
    }
  });

  function closeVideoModal(){
      modal.close();
  }
}