const videos = document.querySelectorAll<HTMLDivElement>('.video');

if (videos) {
  document.addEventListener("DOMContentLoaded", () => {
    videos.forEach(video => {
      const thumbnail = video.querySelector<HTMLImageElement>('.video__thumbnail');
      const icon = video.querySelector<HTMLDivElement>('.video__thumbnail-play-icon');
      const upperText = video.querySelector<HTMLDivElement>('.video__text-upper');
      const lowerText = video.querySelector<HTMLDivElement>('.video__text-lower');
      const mediaElement = video.querySelector<HTMLVideoElement | HTMLIFrameElement>('.video__player');
      const videoWrapper = video.querySelector<HTMLDivElement>('.video__wrapper');

      // Center the text container
      if (lowerText && upperText) {
        const lowerTextHeight = getComputedStyle(lowerText).height;
        upperText.style.height = lowerTextHeight;
      }

      const handleVideoClick = () => {
        thumbnail?.classList.remove('video__thumbnail--visible');
        icon.style.display = 'none';
        upperText.style.opacity = '0';
        videoWrapper?.classList.remove("video__wrapper--with-pointer");

        playVideo(mediaElement);

        videoWrapper?.removeEventListener('click', handleVideoClick);
      }

      videoWrapper?.addEventListener('click', handleVideoClick);
    });

    const playVideo = (video: HTMLVideoElement | HTMLIFrameElement) => {
      if (video instanceof HTMLVideoElement) {
        video.play();
        video.style.pointerEvents = 'auto';
      } else if (video instanceof HTMLIFrameElement) {
        video.style.pointerEvents = 'auto';
        const src = video.src;
        const separator = src.includes('?') ? '&' : '?';
        video.src = `${src}${separator}autoplay=1`;
      }
    }
  });
}