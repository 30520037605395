import { FetchProductsPayload, FetchProductsResponse, FilterCriteria, FilterCriteriaType, NavigationButtonModes, Product } from "./productListing.types";
import axios from "axios";

const productListing = document.querySelector(".product-listing") as HTMLElement || null;

if (productListing) {
    let accordionsContainer = productListing.querySelector('.product-listing__accordions');
    let accordions = productListing.querySelectorAll(".product-listing__accordion") as NodeListOf<HTMLDivElement>;
    let checkboxes = productListing.querySelectorAll(".product-listing__checkbox") as NodeListOf<HTMLInputElement>;
    const rightSide = productListing.querySelector(".product-listing__right") as HTMLElement;
    const gridContainer = productListing.querySelector(".product-listing__grid") as HTMLElement;
    const heroTitle = document.querySelector(".hero__title") as HTMLTitleElement || null;
    const pageId = +productListing.dataset.pageid;
    const categoryElms = productListing.querySelectorAll(".product-listing__filter-key") as NodeListOf<HTMLDivElement>;
    let activeCategory = productListing.querySelector(".product-listing__filter-key.active") as HTMLDivElement;

    let filters: FilterCriteria[] = [];
    let currentPage: number = 1;
    let pageSize: number = 12;
    let products: Product[] = [];
    let resultCount: number = 0;
    let isLoading: boolean = false;

    const fetchProducts = async () => {
        if (isLoading) return;

        const payload: FetchProductsPayload = {
            page: currentPage,
            pageSize,
            currentPage: pageId,
            filters: JSON.stringify(filters)
        };

        isLoading = true;
        updateUI();

        try {
            // For developement:
            // const _response = await axios.get('https://localhost:44332/api/products/search', {
            //     params: payload,
            //     headers: {
            //         'Access-Control-Allow-Origin': '*'
            //     }
            // });

            const _response = await axios.get('/api/products/search', {
                params: payload
            });

            const response = transformResponse(_response.data);
            products = response.products;
            resultCount = response.resultCount;
        } catch (error) {
            console.error('Error fetching products:', error);
            products.length = 0;
        } finally {
            isLoading = false;
            updateUI();
        }
    }

    const transformResponse = (data: any): FetchProductsResponse => ({
        pageCount: data.pages,
        resultCount: data.totalCount,
        products: data.products ? data.products.map((item: any) => ({
            url: item.url,
            quoteUrl: item.quoteUrl,
            shortName: item.shortName,
            longName: item.longName,
            images: item.images ? item.images.map((img: any) => ({
                url: img.url,
                alt: img.alt
            })) : [],
            attributes: item.keyProductAttributes ? item.keyProductAttributes.map((attr: any) => ({
                key: attr.key,
                value: attr.value
            })) : []
        })) : []
    })

    const updateProducts = () => {
        gridContainer.innerHTML = '';

        products.forEach(product => {
            const firstImageUrl = product.images.length > 0 ? product.images[0].url : '';
            const firstImageAlt = product.images.length > 0 ? product.images[0].alt : '';

            const productHTML = `
            <div class='product-grid__item flex flex--column'>
                <a href="${product.url}">
                    <div class="product-grid__item-title-container">
                        <div class='product-grid__item-title'>${product.longName}</div>
                        <div class='product-grid__item-model'>${product.shortName}</div>
                    </div>
                    <div class='product-grid__item-image-container'>
                        <img src='${firstImageUrl}' alt='${firstImageAlt}' class='product-grid__item-image' />
                    </div>
                    <div class="product-grid__details">
                        ${product.attributes.map(attr => `
                            <div class='product-grid__details-container flex flex--justify-between flex--align-center'>
                                <div class='product-grid__details-key'>${attr.key}</div>
                                <div class='product-grid__details-value'>${attr.value}</div>
                            </div>
                        `).join('')}
                    </div>
                </a>
                    <div class='product-grid__buttons flex flex--justify-between flex--align-center'>
                        <a href="${product.url}" class="link link--outline  flex flex--justify-center flex--align-center">
                            <span class="link__label">View More</span>
                        </a>
                        <a href="${product.quoteUrl}" class="link link--primary  flex flex--justify-center flex--align-center">
                            <span class="link__label">Get Quote</span>
                        </a>
                    </div>
            </div>
            `;

            gridContainer.insertAdjacentHTML('beforeend', productHTML);
        });
    };

    const createNavButton = (mode: NavigationButtonModes, onClick: () => void): HTMLElement => {
        const navButton = document.createElement('a');
        navButton.className = `product-listing__pagination-button product-listing__pagination-button--${mode}`;
        let iconClass = '';

        switch (mode) {
            case NavigationButtonModes.first:
                iconClass = 'zmdi zmdi-skip-previous';
                break;
            case NavigationButtonModes.previous:
                iconClass = 'zmdi zmdi-chevron-left';
                break;
            case NavigationButtonModes.next:
                iconClass = 'zmdi zmdi-chevron-right';
                break;
            case NavigationButtonModes.last:
                iconClass = 'zmdi zmdi-skip-next';
                break;
        }

        navButton.innerHTML = `<i class="${iconClass}"></i>`;
        navButton.addEventListener('click', onClick);

        return navButton;
    };

    const updatePaginationUI = () => {
        const paginationContainer = productListing.querySelector('.product-listing__pagination-container') as HTMLElement;
        const totalPages = Math.ceil(resultCount / pageSize);
        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > 5) {
            if (currentPage <= 3) {
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        paginationContainer.innerHTML = '';

        if (totalPages > 1) {
            if (currentPage > 1) {
                paginationContainer.appendChild(createNavButton(NavigationButtonModes.first, () => handlePageClick(1)));
                paginationContainer.appendChild(createNavButton(NavigationButtonModes.previous, () => handlePageClick(currentPage - 1)));
            }

            for (let i = startPage; i <= endPage; i++) {
                const pageButton = createNavButton(NavigationButtonModes.next, () => handlePageClick(i));
                pageButton.className = `product-listing__pagination-button ${i === currentPage ? 'active' : ''}`;
                pageButton.textContent = i.toString();
                paginationContainer.appendChild(pageButton);
            }

            if (currentPage < totalPages) {
                paginationContainer.appendChild(createNavButton(NavigationButtonModes.next, () => handlePageClick(currentPage + 1)));
                paginationContainer.appendChild(createNavButton(NavigationButtonModes.last, () => handlePageClick(totalPages)));
            }
        }


        const paginationLabels = productListing.querySelectorAll('.product-listing--showing-text') as NodeListOf<HTMLSpanElement>;
        if (paginationLabels.length > 0) {
            const startIndex = ((currentPage - 1) * pageSize) + 1;
            const endIndex = Math.min(currentPage * pageSize, resultCount);
            const paginationText = `Showing ${startIndex} to ${endIndex} of ${resultCount} (${totalPages} ${totalPages === 1 ? 'page' : 'pages'})`;

            paginationLabels.forEach(label => label.textContent = paginationText);
        }
    };

    const handlePageClick = (page: number) => {
        currentPage = page;
        fetchProducts();
    };

    const updateUI = () => {
        if (isLoading) {
            rightSide.classList.add("product-listing__right--loading");
            rightSide.classList.remove("product-listing__right--no-results");
            accordions.forEach(f => f.classList.add("product-listing__accordion--loading"));
        } else {
            updateProducts();
            updatePaginationUI();
            updateHero();

            accordions.forEach(f => f.classList.remove("product-listing__accordion--loading"));
            rightSide.classList.remove("product-listing__right--loading");

            if (products.length === 0) {
                rightSide.classList.add("product-listing__right--no-results");
            } else {
                rightSide.classList.remove("product-listing__right--no-results");
            }
        }
    }

    const updateHero = () => {
        if (!heroTitle) return;

        const clonedDiv = activeCategory.cloneNode(true) as HTMLDivElement;

        const span = clonedDiv.querySelector('span');
        if (span) {
            clonedDiv.removeChild(span);
        }

        const { url } = activeCategory.dataset;
        // @ts-ignore
        window.updateCrumbs(clonedDiv.textContent.trim(), url, true);

        heroTitle.textContent = clonedDiv.textContent.trim();
    }

    const updateCustomAccordions = () => {
        const customAccordions = productListing.querySelectorAll(".product-listing__accordion--custom-field") as NodeListOf<HTMLDivElement>;
        customAccordions.forEach(accordion => accordion.remove());

        if (!activeCategory || !activeCategory.dataset.metadata) {
            return;
        }

        try {
            const metadata = JSON.parse(activeCategory.dataset.metadata);

            metadata.forEach((criteria: any) => {
                const newAccordionHTML = `
                    <div class='product-listing__accordion product-listing__accordion--custom-field active'>
                        <div class="product-listing__accordion-upper">
                            <span class="product-listing__accordion-title">${criteria.Title}</span>
                            <i class="zmdi zmdi-chevron-up"></i>
                        </div>
                        <div class="product-listing__accordion-lower">
                            ${criteria.Values.map((value: any) => `
                            <div class="product-listing__checkbox-container">
                                <div class="product-listing__checkbox-label">
                                    <label for="${value.Value}">${value.Value}</label>
                                </div>
                                <input class="product-listing__checkbox" name="${value.Value}" type="checkbox" id="${value.Value}" data-key="${value.FullKey}" data-type="CustomField">
                            </div>
                        `).join('')}
                        </div>
                    </div>
                `;

                accordionsContainer.insertAdjacentHTML('beforeend', newAccordionHTML);
            });

            hookListeners();
        } catch (error) {
            console.error('Error parsing metadata:', error);
        }
    }

    let initialCategoryCounts = {};

    const updateCategoryAccordions = () => {
        const brandsFilters = filters.filter(f => f.Type === FilterCriteriaType.Brand);

        if (brandsFilters.length === 0) {
            categoryElms.forEach(categoryElm => {
                categoryElm.classList.remove("disabled");
                const spanElm = categoryElm.querySelector('span');
                if (spanElm) {
                    spanElm.textContent = `(${initialCategoryCounts[categoryElm.dataset.key]})`;
                }
            });

            if (!activeCategory) {
                updateCategory(productListing.querySelector(".product-listing__filter-key"));
            }

            return;
        }

        categoryElms.forEach(categoryElm => {
            const isEnabled = brandsFilters.some(brand =>
                brand.Metadata.some((metadata: any) => metadata.CategoryKey === categoryElm.dataset.key)
            );

            let totalCount = 0;
            if (isEnabled) {
                categoryElm.classList.remove("disabled");
                brandsFilters.forEach(brand => {
                    brand.Metadata.forEach((metadata: any) => {
                        if (metadata.CategoryKey === categoryElm.dataset.key) {
                            totalCount += metadata.CategoryCount;
                        }
                    });
                });
            } else {
                categoryElm.classList.add("disabled");
            }

            const spanElm = categoryElm.querySelector('span');
            if (spanElm) {
                totalCount = Math.min(totalCount, initialCategoryCounts[categoryElm.dataset.key])
                const countText = isEnabled ? `(${totalCount})` : '(0)';
                spanElm.textContent = countText;
            }
        });

        if (activeCategory?.classList.contains("disabled")) {
            updateCategory(productListing.querySelector(".product-listing__filter-key:not(.disabled)"));
        }

        fetchProducts();
    };

    const updateCategory = (elm: HTMLDivElement) => {
        if (activeCategory === elm) return;

        activeCategory?.classList.remove("active");
        activeCategory = elm;
        activeCategory?.classList.add("active");

        if (activeCategory) {
            filters = filters.filter(f => f.Type !== FilterCriteriaType.Category);
            filters.push({ Value: activeCategory.dataset.key, Type: FilterCriteriaType.Category });
        }
    }

    const hookAccordionListeners = () => {
        accordions.forEach((accordion) => {
            accordion.addEventListener("click", (e: Event) => {
                const targetElm = e.target as HTMLElement;
                if (!targetElm.closest(".product-listing__accordion-upper")) return;

                accordion.classList.toggle("active");

                const isActive = accordion.classList.contains("active");
                const arrow = accordion.querySelector(".zmdi");
                if (isActive) {
                    arrow.classList.remove("zmdi-chevron-down");
                    arrow.classList.add("zmdi-chevron-up");
                } else {
                    arrow.classList.remove("zmdi-chevron-up");
                    arrow.classList.add("zmdi-chevron-down");
                }
            });
        });
    }

    const hookFilterListeners = () => {
        categoryElms.forEach((key) => {
            key.addEventListener("click", (e: Event) => {
                const target = (e.target as HTMLElement).closest(".product-listing__filter-key") as HTMLDivElement;

                if (target === activeCategory || target.classList.contains("disabled")) return;
                
                filters = filters.filter(f => f.Type === FilterCriteriaType.Brand);
                updateCategory(target);

                updateHero();
                updateCustomAccordions();
                fetchProducts();
            });
        });

        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener("change", (e: Event) => {
                const target = e.target as HTMLInputElement;
                const key = target.dataset.key;
                const type = target.dataset.type as FilterCriteriaType;
                const value = target.name;

                const existingFilterIndex = filters.findIndex(fc => {
                    if (fc.Type === FilterCriteriaType.Category || type == FilterCriteriaType.Brand) {
                        return fc.Value === key;
                    }

                    return fc.Key === key && fc.Value === value;
                });

                if (target.checked) {
                    if (existingFilterIndex === -1) {
                        if (type === FilterCriteriaType.Brand) {
                            filters.push({ Value: key, Type: type, Metadata: JSON.parse(target.dataset.metadata) });
                        }
                        else if (type === FilterCriteriaType.Category) {
                            filters.push({ Value: key, Type: type });
                        } else {
                            filters.push({ Key: key, Value: value, Type: type });
                        }
                    }
                } else {
                    if (existingFilterIndex !== -1) {
                        filters.splice(existingFilterIndex, 1);
                    }
                }

                if (type === FilterCriteriaType.Brand) {
                    updateCategoryAccordions();
                }

                fetchProducts();
            });
        });
    }

    const hookPageSizeListener = () => {
        const pageSizeSelect = productListing.querySelector(".product-listing__select") as HTMLSelectElement;

        pageSizeSelect.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLSelectElement;
            const newPageSize = parseInt(target.value);

            const newTotalPages = Math.ceil(resultCount / newPageSize);

            if (currentPage > newTotalPages) {
                currentPage = newTotalPages;
            }

            pageSize = newPageSize;
            fetchProducts();
        });
    }

    const hookListeners = () => {
        accordionsContainer = productListing.querySelector('.product-listing__accordions');
        accordions = productListing.querySelectorAll(".product-listing__accordion") as NodeListOf<HTMLDivElement>;
        checkboxes = productListing.querySelectorAll(".product-listing__checkbox") as NodeListOf<HTMLInputElement>;

        hookAccordionListeners();
        hookFilterListeners();
        hookPageSizeListener();
    }

    const toggleAccordions = () => {
        accordions.forEach((accordion) => {
            const isActive = accordion.classList.contains("active");
            const arrow = accordion.querySelector(".zmdi");
            if (isActive) {
                arrow.classList.remove("zmdi-chevron-down");
                arrow.classList.add("zmdi-chevron-up");
            } else {
                arrow.classList.remove("zmdi-chevron-up");
                arrow.classList.add("zmdi-chevron-down");
            }
        });
    }

    const initPayload = () => {
        if (activeCategory) {
            filters.push({ Value: activeCategory.dataset.key, Type: FilterCriteriaType.Category });
            updateCustomAccordions();
        }
    }

    const updateOnPageLoad = () => {
        const url = window.location.pathname;
        const split = url.split('/');

        if (split.length >= 3) {
            const categoryUrl = split[2].replace(/-/g, ' ');
            let category: HTMLDivElement = null;
            Array.from(categoryElms).forEach(categoryElm => {
                const clonedDiv = categoryElm.cloneNode(true) as HTMLDivElement;

                const span = clonedDiv.querySelector('span');
                if (span) {
                    clonedDiv.removeChild(span);
                }

                const text = clonedDiv.textContent.trim();

                if (text.toLowerCase() === categoryUrl.toLowerCase()) {
                    category = categoryElm;
                    return;
                }
            });

            if (category) {
                updateCategory(category);
            }
        }
    }

    const init = () => {
        updateOnPageLoad();
        updateUI();
        initPayload();
        fetchProducts();
        toggleAccordions();
        hookListeners();

        categoryElms.forEach(categoryElm => {
            const spanElm = categoryElm.querySelector('span');
            if (spanElm) {
                const count = spanElm.textContent?.replace(/\(|\)/g, '');
                initialCategoryCounts[categoryElm.dataset.key] = count;
            }
        });
    }

    document.addEventListener("DOMContentLoaded", init);
}