const brandHero = document.querySelector(".brand-hero");

if (brandHero) {
    let db = {
        industries: []
    };
    let industryDropdowns = document.querySelectorAll("select[name=industry-select]") as NodeListOf<HTMLSelectElement>;
    let applicationDropdowns = document.querySelectorAll("select[name=application-select]") as NodeListOf<HTMLSelectElement>;
    let productDropdowns = document.querySelectorAll("select[name=product-select]") as NodeListOf<HTMLSelectElement>;
    let searchButton = document.querySelector(".brand-hero").querySelector(".button") as HTMLButtonElement;

    let selectedIndustry: HTMLOptionElement,
        selectedApplication: HTMLOptionElement,
        selectedProduct: HTMLOptionElement;

    let targetLink = "";

    const populateDb = () => {
        let elm = document.querySelector(".brand-hero__bottom") as HTMLElement;
        let json = JSON.parse(elm.dataset.herodropdown);

        json.industries.forEach(industry => {
            industry.applications = json.applications
                .filter(application => application.Industries.includes(String(industry.ID)))
                .map(application => {
                    return {
                        ...application,
                        products: json.products.filter(product =>
                            product.Applications.includes(String(application.ID))
                        )
                    };
                });
        });

        db.industries = json.industries;
    }

    const init = () => {
        populateDb();
        populateIndustries();
        hookListeners();
    };

    const loadInitialValues = () => { 
        industryDropdowns.forEach(dropdown => dropdown.selectedIndex = 0);
        applicationDropdowns.forEach(dropdown => dropdown.selectedIndex = 0);
        productDropdowns.forEach(dropdown => dropdown.selectedIndex = 0);
    }

    const updateSearchButton = () => {
        const anyIndustrySelected = Array.from(industryDropdowns).some(select => select.selectedIndex > 0);
        if (!anyIndustrySelected || !(targetLink = generateLink())) {
            searchButton.disabled = true;
            return;
        } else searchButton.disabled = false;
    }

    const generateLink = () => {
        if (selectedProduct) return selectedProduct.dataset.url;
        if (selectedApplication) return selectedApplication.dataset.url;
        if (selectedIndustry) return selectedIndustry.dataset.url;

        return "";
    }

    const onSearchPress = (e: Event) => {
        e.preventDefault();
        if (targetLink) {
            window.location.href = targetLink;
        }
    }

    const hookListeners = () => {
        searchButton.addEventListener("click", onSearchPress);

        industryDropdowns.forEach(industryDropDown => {
            industryDropDown.addEventListener("change", (e) => {
                let selectElement = e.target as HTMLSelectElement;
                let selectedOption = selectElement.options[selectElement.selectedIndex];
                selectedIndustry = selectedOption;
                populateApplications(selectedOption.value);
                updateSearchButton();
            });
        });

        applicationDropdowns.forEach(applicationDropDown => {
            applicationDropDown.addEventListener("change", (e) => {
                let selectElement = e.target as HTMLSelectElement;
                let selectedOption = selectElement.options[selectElement.selectedIndex];
                selectedApplication = selectedOption;
                populateProducts(selectedIndustry.value, selectedOption.value);
                updateSearchButton();
            });
        });

        productDropdowns.forEach(productDropDown => {
            productDropDown.addEventListener("change", (e) => {
                let selectElement = e.target as HTMLSelectElement;
                let selectedOption = selectElement.options[selectElement.selectedIndex];
                selectedProduct = selectedOption;
                updateSearchButton();
            });
        });
    }

    const clearOptions = (selectElements: NodeListOf<HTMLSelectElement>) => {
        selectElements.forEach(selectElement => {
            for (let i = selectElement.options.length - 1; i > 0; i--) {
                selectElement.remove(i);
            }
            selectElement.selectedIndex = 0;
        })
    }

    const addOption = (selectElements: NodeListOf<HTMLSelectElement>, optionData: { value: string, text: string, url: string }) => {
        selectElements.forEach(selectElement => {
            const option = document.createElement("option");
            option.value = optionData.value;
            option.text = optionData.text;
            option.dataset.url = optionData.url; 
            selectElement.add(option);
        });
    }

    const populateIndustries = () => {
        selectedIndustry = undefined;
        clearOptions(industryDropdowns);
        db.industries.forEach(industry => {
            addOption(industryDropdowns, { value: industry.ID, text: industry.Name, url: industry.Url });
        });
    }

    const populateProducts = (industryId: string, applicationId: string) => {
        let products = db.industries.find(industry => String(industry.ID) === industryId)
            .applications.find(application => String(application.ID) === applicationId).products;

        clearOptions(productDropdowns);
        selectedProduct = undefined;
        products.forEach(product => {
            addOption(productDropdowns, { value: product.ID, text: product.Name, url: product.Url })
        });
    }

    const populateApplications = (industryId: string) => {
        let applications = db.industries.find(industry => String(industry.ID) === industryId).applications;

        clearOptions(applicationDropdowns);
        clearOptions(productDropdowns);
        selectedApplication = selectedProduct = undefined;
        applications.forEach(application => {
            addOption(applicationDropdowns, { value: application.ID, text: application.Name, url: application.Url });
        });
    }

    document.addEventListener("DOMContentLoaded", init);

    window.addEventListener("pageshow", loadInitialValues);
}