const handleAnchors = () => {
    const observer = new ResizeObserver(() => {
        const anchors = document.querySelectorAll("#anchor") as NodeListOf<HTMLInputElement>;

        anchors.forEach((anchor) => {
            const target = document.querySelector(anchor.value) as HTMLElement;

            if (target) {
                observer.disconnect();

                setTimeout(() => {
                    target.scrollIntoView({ behavior: 'smooth' });
                }, 50);
            }
        });

    });

    observer.observe(document.body);
}

const handleForms = () => {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes.length > 0) {
                const sourceWebsiteUrl = document.querySelector(".sourcewebsiteurl input") as HTMLInputElement;

                if (sourceWebsiteUrl) {
                    sourceWebsiteUrl.value = window.location.href;
                    observer.disconnect();
                }
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
}

handleAnchors();
handleForms();