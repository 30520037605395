var singleNews = document.querySelector(".single-news");

if (singleNews) {
    let lastElm:HTMLElement | undefined;

    document.addEventListener("DOMContentLoaded", () => { 
        hookListener();
        const firstItem = document.querySelector(".single-news__tabs-item") as HTMLElement;
        firstItem.classList.add("active");
        showContent(firstItem.dataset.id);
        lastElm = firstItem;   
    })

    const hookListener = () => { 
        var tabsItem = document.querySelectorAll(".single-news__tabs-item");
        tabsItem.forEach((item) => {
            item.addEventListener("click", (e) => {
                const targetElm = e.currentTarget as HTMLElement;

                if (lastElm !== null) {
                    lastElm.classList.remove("active");
                    hideContent(lastElm.dataset.id);
                }

                targetElm.classList.add("active");
                showContent(targetElm.dataset.id);
                lastElm = targetElm;
            })
        })
    }

    const showContent = (id: string) => 
        document.querySelector(`.single-news__right-content[data-id="${id}"]`).classList.add("active");

    const hideContent = (id: string) =>
        document.querySelector(`.single-news__right-content[data-id="${id}"]`).classList.remove("active");
}