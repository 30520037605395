import Glide from '@glidejs/glide'

if(document.querySelector('.brands-carousel__glide')) {
  new Glide('.brands-carousel__glide', {
      type: 'carousel',
      perView: 6,
      perSwipe: "|",
      breakpoints: {
          1300: {
            perView: 5,
          },
          1100: {
            perView: 4,
          },
          850: {
            perView: 3,
          },
          600: {
            perView: 2,
          },
          460: {
            perView: 1,
          },
        },
  }).mount();
}