const search = document.querySelector(".search") as HTMLDivElement;

if (search) {
    const pageNumber = search.querySelector("#pageNumber") as HTMLInputElement;
    const searchForm = search.querySelector("#searchForm") as HTMLFormElement;

    const hookListeners = () => {
        const pagination = search.querySelectorAll(".search__pagination") as NodeListOf<HTMLDivElement>;
        const searchInput = search.querySelector(".search__input") as HTMLInputElement;
        const button = searchForm.querySelector(".button") as HTMLInputElement;

        pagination.forEach((link) => {
            link.addEventListener("click", (e: Event) => {
                e.preventDefault();
                pageNumber.value = (e.target as HTMLDivElement).innerText;

                searchForm.submit();
            });
        });

        searchInput.addEventListener("input", () => {
            if (searchInput.value.trim() === "") {
                button.disabled = true;
            } else {
                button.disabled = false;
            }
        });
    }

    document.addEventListener("DOMContentLoaded", hookListeners);
}