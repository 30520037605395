import Glide from '@glidejs/glide'
import axios from "axios";

var productDetails = document.querySelector(".product-details") as HTMLDivElement | null;

if (productDetails) {
    let activeImage: HTMLElement, activeTab: HTMLElement;
    const pagination = document.querySelector('#glide__pagination') as HTMLElement;
    const mainImage = document.querySelector('#mainImage') as HTMLImageElement;
    const formFieldsMap = new Map<string, string>(); // key: field id, value: field alias

    const onImagePress = (event: Event) => {
        const clickedItem = (event.target as HTMLElement).closest('.glide__slide') as HTMLElement;
        if (!clickedItem) return;
        if (clickedItem === activeImage) return;

        clickedItem.classList.add('active');
        if (activeImage) {
            activeImage.classList.remove('active');
        }

        activeImage = clickedItem;
        updatePagination();
        updateImage(clickedItem.querySelector("img"));
    }

    const updateImage = (slide: HTMLImageElement) => {
        mainImage.src = slide.src;
    }

    const updatePagination = () => {
        const glideSlides = productDetails.querySelectorAll('.glide__slide') as any;
        const max = glideSlides.length;
        const current = [...glideSlides].findIndex((elm) => elm.classList.contains('active')) + 1;

        pagination.innerText = `${current} / ${max}`
    }

    const init = () => {
        if (pagination) {
            new Glide('.product-details__glide', {
                type: 'slider',
                perView: 3
            }).mount();

            const firstSlide = productDetails.querySelector('.glide__slide') as HTMLImageElement;
            activeImage = firstSlide;
            firstSlide.classList.add('active');
            updatePagination();
            updateImage(firstSlide.querySelector("img"));
        }

        const anchor = productDetails.querySelector('#anchor') as HTMLInputElement;
        if (anchor) {
            const target = document.querySelector(anchor.value) as HTMLElement;
            if (target) {
                setTimeout(() => {
                    smoothScrollTo(target);
                }, 150);
            }
        }

        const firstTab = productDetails.querySelector('.product-details__bar-item') as HTMLElement;
        firstTab.classList.add('active');
        const firstContent = productDetails.querySelector('.product-details__specs-inner') as HTMLElement;
        firstContent.classList.add('active');
        activeTab = firstTab;

        initForm();
    }

    const initForm = async () => {
        const form = document.querySelector('.umbraco-forms-page') as HTMLDivElement | null;

        if (form) {
            const productField = form.querySelector(".umbraco-forms-field.product select") as HTMLSelectElement | null;
            const formId = (document.querySelector('input[name="FormId"][type="hidden"]') as HTMLInputElement)?.value;

            if (!productField) return;

            const productName = (productDetails.querySelector(".product-details__upper-product-name") as HTMLHeadingElement).innerText;
            productField.options.length = 0;
            productField.options[0] = new Option(productName, productName);
            productField.disabled = true;

            if (!formId || (await canLoadFormMap(formId)) === false) {
                form.classList.add("disabled");
                return;
            }

            form.querySelector("[type=submit]").addEventListener('click', (e) => onFormSubmit(e, form, formId));
        }
    }

    const canLoadFormMap = async (formId: string): Promise<boolean> => {
        try {
            // For developement:
            // const response = await axios.get(`https://localhost:44332/umbraco/forms/api/v1/definitions/${formId}`);

            const response = await axios.get(`/umbraco/forms/api/v1/definitions/${formId}`);

            response.data.pages.forEach((page: any) => {
                page.fieldsets.forEach((fieldset: any) => {
                    fieldset.columns.forEach((column: any) => {
                        column.fields.forEach((field: any) => {
                            formFieldsMap.set(field.id, field.alias);
                        });
                    });
                });
            })

            return true;
        } catch (error) {
            console.log(error)
            return false;
        }
    }

    const onFormSubmit = (e: Event, form: HTMLDivElement, formId: string) => {
        e.preventDefault();
        const payload = createFormPayload(form);
        submitFormAjax(payload, form, formId);
    }

    const createFormPayload = (form: HTMLDivElement): any => {
        const payload: any = {values: {}};
        const { values } = payload;

        form.querySelectorAll("input").forEach((input: HTMLInputElement) => {
            if (formFieldsMap.has(input.id)) {
                if (input.type === "checkbox") {
                    values[formFieldsMap.get(input.id) as string] = input.checked ? "on" : "off";
                } else {
                    values[formFieldsMap.get(input.id) as string] = input.value;
                }
            }
        });

        form.querySelectorAll("select").forEach((select: HTMLSelectElement) => {
            if (formFieldsMap.has(select.id)) {
                values[formFieldsMap.get(select.id) as string] = select.value;
            }
        });

        return payload;
    }

    const submitFormAjax = async (payLoad: any, form: HTMLDivElement, formId: string): Promise<boolean> => {
        try {
            form.classList.add("disabled");

            // For developement:
            // const response = await axios.post(`https://localhost:44332/umbraco/forms/api/v1/entries/${formId}`, payLoad);
            
            const response = await axios.post(`/umbraco/forms/api/v1/entries/${formId}`, payLoad);
            printFormOutput(form, response.data.messageOnSubmit);
            
            return true;
        } catch (error) {
            console.error("Error while submitting form: ", error);
            const errors = Object.values(error.response.data.errors).flat() as string[];
            printFormOutput(form, errors);

            return false;
        } finally {
            form.classList.remove("disabled");
        }
    }

    const printFormOutput = (form: HTMLDivElement, message: string | string[]) => {
        let list = form.querySelector(".error-list") as HTMLUListElement;

        if (list) list.innerHTML = "";
        else {
            list = document.createElement("ul");
            list.classList.add("error-list");
        }

        if (message instanceof Array) {
            if (message.length == 0) return;
    
            message.forEach((error) => {
                const li = document.createElement("li");
                li.innerText = error;
                li.classList.add("error-list__item");
                list.appendChild(li);
            });
        } else {
            form.innerHTML = message;
            form.classList.add("success");
        }

        form.querySelector(".umbraco-forms-fieldset")?.insertBefore(list, form.querySelector(".umbraco-forms-fieldset")?.firstElementChild);
    }

    const onTabPress = (event: Event) => {
        const target = event.target as HTMLElement;
        if (target === activeTab) return;

        activeTab.classList.remove('active');
        target.classList.add('active');

        const tabKey = target.dataset.key;
        productDetails.querySelector(`.product-details__${activeTab.dataset.key}-inner`).classList.remove('active');
        productDetails.querySelector(`.product-details__${tabKey}-inner`).classList.add('active');

        activeTab = target;
    }

    const smoothScrollTo = (element: HTMLElement) => {
        element?.scrollIntoView({ behavior: 'smooth' });
    }

    const hookListeners = () => {
        if (mainImage) {
            var glideContainer = productDetails.querySelector(".product-details__glide");
            glideContainer?.addEventListener('click', onImagePress);
        }

        var tabs = productDetails.querySelectorAll(".product-details__bar-item");
        tabs.forEach((tab) => {
            tab.addEventListener('click', onTabPress);
        });

        const quoteButton = productDetails.querySelector(".product-details__upper-button") as HTMLDivElement;
        quoteButton?.addEventListener('click', (e: Event) => {
            const url = quoteButton.querySelector("a")?.href;

            if (url) {
                const fragmentIdentifier = new URL(url).hash;

                const target = document.querySelector(fragmentIdentifier) as HTMLElement;
                if (target) {
                    e.preventDefault();
                    smoothScrollTo(target);
                }
            }
        });
    }

    document.addEventListener('DOMContentLoaded', () => {
        init();
        hookListeners();
    });
}