const widget = document.querySelector('.widget') as HTMLElement;

if (widget) {
    const toggleButton = widget.querySelector('.widget__toggle') as HTMLDivElement;
    const list = widget.querySelector('.widget__list') as HTMLDivElement;
    const toggleIcon = toggleButton.querySelector('.widget__toggle-icon') as HTMLImageElement;

    const toggleWidget = () => {
        list.classList.toggle('widget__list--active');
        const isOpen = list.classList.contains('widget__list--active');
        const iconSrc = isOpen ? toggleIcon.dataset.closesrc : toggleIcon.dataset.opensrc;
        toggleIcon.src = iconSrc;
    };

    const styleWidget = () => {
        const items = widget.querySelectorAll(".widget__list-item") as NodeListOf<HTMLLinkElement>;

        if (items.length != 3 && items[1]) {
            items[1].style.transform = "unset";
            list.style.marginRight = "0";
        }
    }

    const onChatClick = () => {
        widget.querySelector(".widget__list-item--chat")?.addEventListener('click', () => {
            //@ts-ignore
            embedded_svc.bootstrapEmbeddedService();
        })
    }

    const init = () => {
        styleWidget();
        onChatClick();
        toggleButton.addEventListener('click', toggleWidget);
    }

    document.addEventListener('DOMContentLoaded', init);
}