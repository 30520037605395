var sidebar = document.querySelector(".industry-sidebar");

if (sidebar) {
    let lastElm:HTMLElement | undefined;

    document.addEventListener("DOMContentLoaded", () => { 
        const firstItem = document.querySelector(".industry-sidebar__column-item.industry-sidebar__menu") as HTMLElement;
        if (firstItem) {
            hookListener();
            firstItem.classList.add("active");
            showContent(firstItem.dataset.id);
            lastElm = firstItem;   
        }
    })

    const hookListener = () => { 
        var container = document.querySelector(".industry-sidebar__column-item-container");
        var columnItems = container.querySelectorAll(".industry-sidebar__column-item");
        columnItems.forEach((item) => {
            item.addEventListener("click", (e) => {
                const targetElm = e.currentTarget as HTMLElement;

                if (lastElm !== null) {
                    lastElm.classList.remove("active");
                    hideContent(lastElm.dataset.id);
                }

                targetElm.classList.add("active");
                showContent(targetElm.dataset.id);
                lastElm = targetElm;
            })
        })
    }

    const showContent = (id: string) => 
        document.querySelector(`.industry-sidebar__left-content[data-id="${id}"]`).classList.add("active");

    const hideContent = (id: string) =>
        document.querySelector(`.industry-sidebar__left-content[data-id="${id}"]`).classList.remove("active");
}