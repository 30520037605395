const heroVideo = document.querySelector('.hero__video') as HTMLVideoElement;
const breadcrumbs = document.querySelector('.hero__breadcrumbs')

if (breadcrumbs) {
  const updateCrumbs = (anchorText: string = undefined, link: string = undefined, skipBeforeLast: boolean = false) => {
    let path = location.href.split('/').slice(3);

    const linkPaths = [];

    for (let i = 0; i < path.length; i++) {
      if (skipBeforeLast && i === path.length - 2)
        continue;

      const component = path[i];

      let anchorText = decodeURIComponent(component).split('.')[0].split('?')[0];
      anchorText = anchorText.charAt(0).toUpperCase() + anchorText.slice(1);
      anchorText = anchorText.split('#')[0];

      const link = '/' + path.slice(0, i + 1).join('/');

      linkPaths.push({ "main": anchorText, "link": link });
    }

    if (anchorText && link && linkPaths.length > 0) {
      linkPaths[linkPaths.length - 1] = { "main": anchorText, "link": link };
    }

    const breadcrumbs = document.querySelector('.hero__breadcrumbs');
    breadcrumbs.innerHTML = '';

    linkPaths.forEach(path => {
      let a = document.createElement("a");

      a.textContent = path.main;
      a.href = path.link;
      a.className = "hero__breadcrumb flex flex--align-center flex--justify-center";

      breadcrumbs.appendChild(a);
    })
  }

  // @ts-ignore
  window.updateCrumbs = updateCrumbs;
  document.addEventListener('DOMContentLoaded', () => updateCrumbs());
}

if (heroVideo) {
  const muteButton = document.querySelector('.hero__mute-btn') as HTMLButtonElement;

  const hookListeners = () => {
    if (muteButton) {
      muteButton.addEventListener('click', () => {
        if (heroVideo.muted) {
          heroVideo.muted = false;
          muteButton.classList.remove('zmdi-volume-off');
          muteButton.classList.add('zmdi-volume-up');
        } else {
          heroVideo.muted = true;
          muteButton.classList.remove('zmdi-volume-up');
          muteButton.classList.add('zmdi-volume-off');
        }
      });
    }
  }

  document.addEventListener('DOMContentLoaded', hookListeners);
}