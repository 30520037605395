var brandCategories = document.querySelector(".brand-categories");

if (brandCategories) {
    let lastElm: Element;

    document.addEventListener("DOMContentLoaded", () => {
        init();
        hookListeners();
        updateImage();
    });

    const hookListeners = () => {
        var images = document.querySelectorAll(".brand-categories__item");
        images.forEach((image) => {
            image.addEventListener("click", onImagePress);
        });
    }

    const onImagePress = (e: Event) => {
        var target = e.target as Element;
        console.log(target)
        if (lastElm) {
            lastElm.classList.remove("active");
        }
        target.classList.add("active");
        lastElm = target;
        updateImage();
    }

    const updateImage = () => {
        let selectedImage: HTMLImageElement = document.querySelector(".brand-categories__item.active");
        selectedImage = selectedImage.querySelector("img") as HTMLImageElement;
        const mainImage = document.querySelector("#mainImage") as HTMLImageElement;
        mainImage.src = selectedImage.src;
    }

    const init = () => {
        var firstImage = document.querySelector(".brand-categories__item");
        firstImage.classList.add("active");
        lastElm = firstImage;
    }
}