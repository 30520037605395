export type FetchProductsResponse  = {
    pageCount: number;
    resultCount: number;
    products: Product[];
}

export type Product = {
    url: string;
    quoteUrl: string;
    shortName: string;
    longName: string;
    images: Image[];
    attributes: ProductAttributes[];
}

export enum NavigationButtonModes {
    first = "previous",
    previous = "left",
    next = "next",
    last = "right"
}

type Image = {
    url: string;
    alt: string;
}

type ProductAttributes = {
    key: string;
    value: string;
}

export type FilterCriteria = {
    Key?: string;
    Value: string;
    Type: FilterCriteriaType;
    Metadata?: any;
}

export enum FilterCriteriaType {
    Category = "Category",
    Brand = "Brand",
    LongName = "LongName",
    ShortName = "ShortName",
    CustomField = "CustomField"
}

export type FetchProductsPayload = {
    page: number;
    pageSize: number;
    filters: string;
    currentPage: number;
}