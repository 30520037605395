import axios from "axios";
import { EmailSubscriptionPaylod, EmailSubscriptionResponse } from "./footer.types";

const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
        if (mutation.addedNodes) {
            mutation.addedNodes.forEach((node: HTMLElement) => {
                if (node.classList && node.classList.contains('footer')) {
                    observer.disconnect();
                    onFooterLoad(node as HTMLDivElement);
                }
            });
        }
    });
});

observer.observe(document.body, { childList: true, subtree: true });

const onFooterLoad = (footer: HTMLDivElement) => {
    const { formId } = footer.dataset;

    if (formId) {
        const emailElm = footer.querySelector<HTMLInputElement | null>("#email");
        const footerTop = footer.querySelector<HTMLDivElement>(".footer__top-email");
        const submitButton = footerTop.querySelector<HTMLButtonElement>(".button");

        let isLoading = false;
        let isSuccess = true;

        const submitForm = async () => {
            if (isLoading) return;

            isLoading = true;
            updateUI();

            const payload: EmailSubscriptionPaylod = {
                email: emailElm.value
            }

            try {
                // For developement:
                // const _response = await axios.post(`https://localhost:44332/umbraco/forms/api/v1/entries/${formId}`, {
                //     "values": {
                //         ...payload
                //     }
                // });

                const _response = await axios.post(`/umbraco/forms/api/v1/entries/${formId}`, {
                    "values": {
                        ...payload
                    }
                });

                const response = transformResponse(_response.data);
                isSuccess = response.success;
            } catch (error) {
                console.error("Error while submitting form: ", error);
                isSuccess = false;
            } finally {
                isLoading = false;
                updateUI();
            }
        }

        const transformResponse = (response: any): EmailSubscriptionResponse => {
            if (response.messageOnSubmit) {
                return {
                    success: true,
                    message: response.messageOnSubmit
                };
            }

            return {
                success: false,
                message: response.title
            };
        }

        const isValidEmailAddress = (email: string) =>
            (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));

        const hookListeners = () => {
            emailElm.addEventListener("input", () => {
                submitButton.disabled = !isValidEmailAddress(emailElm.value);
            });

            submitButton.addEventListener("click", submitForm);
        }

        const updateUI = () => {
            if (isLoading) {
                submitButton.disabled = emailElm.disabled = true;
            } else {
                submitButton.disabled = emailElm.disabled = false;

                if (isSuccess) {
                    footerTop.classList.add("footer__top-submitted--success");
                } else {
                    footerTop.classList.add("footer__top-submitted--failure");
                }
            }
        }

        hookListeners();
    }
}