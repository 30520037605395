 if(document.querySelector('.cta-panels') != null) {
    document.addEventListener("DOMContentLoaded", function () {
        function isInViewport(element: HTMLElement): boolean {
            const bounding = element.getBoundingClientRect();

            if (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                return true;
            } else {
                return false;
            }
        }

        function animateStatsPanel(): void {
            const panels = document.querySelector('.cta-panels__stats') as HTMLElement;
        
            if (panels && isInViewport(panels)) {
                const counters = document.querySelectorAll('.cta-panels__stat-value');
        
                counters.forEach((counter: Element) => {
                    const targetValue: number = +counter.getAttribute("data-value")!;
                    let currentValue: number = +counter.textContent!.replace('K', '') * (counter.textContent!.includes('K') ? 1000 : 1);
                    
                    const animationInterval = setInterval(() => {
                        currentValue += (targetValue - currentValue) / 10; 
                        if (Math.abs(currentValue - targetValue) < 1) { 
                            currentValue = targetValue;
                        }
                        if (currentValue >= 1000) {
                            (counter as HTMLElement).innerText = (currentValue / 1000).toFixed(0) + 'K';
                        } else {
                            (counter as HTMLElement).innerText = Math.ceil(currentValue).toString();
                        }
                        
                        if (currentValue === targetValue) { 
                            clearInterval(animationInterval);
                        }
                    }, 40); 
                });
            }
        }

        animateStatsPanel();

        window.addEventListener('scroll', () => {
            animateStatsPanel();
        });
    });
}