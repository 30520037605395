import Glide from '@glidejs/glide';

const getInTouch = document.querySelector(".get-in-touch");

if (getInTouch) {
    let activeIndex: number = 0, numberOfQuotes: number;
    const nextButton = document.querySelector(".get-in-touch__button--next") as HTMLButtonElement;
    const prevButton = document.querySelector(".get-in-touch__button--prev") as HTMLButtonElement;
    let quotesGlide: Glide;
    let images: NodeListOf<HTMLElement>;

    const initGlide = () => {
        quotesGlide = new Glide('.get-in-touch__quote-glide', {
            type: 'slider',
            perView: 1,
            startAt: 0
        });

        quotesGlide.on("run.after", () => {
            images[activeIndex].classList.remove("active");
            activeIndex = quotesGlide.index;
            images[activeIndex].classList.add("active");

            toggleButtons();
        });

        quotesGlide.mount();
    }

    const toggleButtons = () => {
        if (!nextButton || !prevButton) return;

        nextButton.disabled = activeIndex + 1 >= numberOfQuotes;
        prevButton.disabled = activeIndex - 1 < 0;
    }

    const alignBottomElements = () => {
        const leftBottom = document.querySelector('.get-in-touch__left-bottom') as HTMLElement;
        const rightBottom = document.querySelector('.get-in-touch__right-bottom') as HTMLElement;
        if (!leftBottom && !rightBottom) return;
        
        const wrapper = document.querySelector('.get-in-touch__wrapper') as HTMLElement;
        const overlay = document.querySelector('.get-in-touch__overlay') as HTMLElement;
        const leftBottomOffset = 21.5;
    
        const wrapperRect = wrapper.getBoundingClientRect();
        const overlayRect = overlay?.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(wrapper);
        const paddingTop = parseFloat(computedStyle.paddingTop);

        const wrapperBottomPosition = wrapperRect.top + wrapperRect.height - paddingTop;
        const overlayBottomPosition = overlayRect?.top + overlayRect?.height - paddingTop + 25;
    
        const referenceBottomPosition = Math.max(wrapperBottomPosition, overlayBottomPosition);
    
        if (leftBottom) leftBottom.style.top = `${referenceBottomPosition + leftBottomOffset}px`;
        if (rightBottom) rightBottom.style.top = `${referenceBottomPosition}px`;
    };

    const init = () => {
        const quotes = document.querySelectorAll(".get-in-touch__quote") as NodeListOf<HTMLElement>;
        numberOfQuotes = quotes.length;

        images = document.querySelectorAll(".get-in-touch__person-container") as NodeListOf<HTMLElement>;
        if (images.length > 0) {
            images[0].classList.add("active");
        }
    }

    const showMenu = (dir: string) => {
        quotesGlide.go(dir);

        images[activeIndex].classList.remove("active");
        activeIndex = quotesGlide.index;
        images[activeIndex].classList.add("active");
        
        toggleButtons();
    }
    
    const hookListeners = () => {
        const buttons = document.querySelectorAll(".get-in-touch__button");
        if (buttons.length < 2) return;

        const prevButton = buttons[0];
        const nextButton = buttons[1];

        nextButton.addEventListener("click", () => { 
            showMenu(">");
        });
        prevButton.addEventListener("click", () => { 
            showMenu("<");
        })
    }

    const resizeObserver = new ResizeObserver(entries => {
        for (let _ of entries) {
            alignBottomElements();
        }
    });

    document.addEventListener("DOMContentLoaded", () => {
        init();
        initGlide();
        toggleButtons();
        hookListeners();
        alignBottomElements();

        const wrapperElement = document.querySelector('.get-in-touch__wrapper');
        resizeObserver.observe(wrapperElement);
    })
}