if (document.querySelector('.services') != null) {
    const tabInputs = document.querySelectorAll<HTMLInputElement>('.services__radio');
    const tabLabels = document.querySelectorAll<HTMLInputElement>('.services__label');
    const cards = document.querySelectorAll<HTMLInputElement>('.services__cards');

    let activeTab = 0;

    if (tabInputs.length > 0) {
        tabInputs.forEach((input) => {
            input.addEventListener('change', handleInputChange);
        });

        tabLabels.forEach((input) => {
            input.addEventListener('keydown', (e) => {
                if (e.key == 'Enter') {
                    handleInputChange(e, 'keydown');
                }
            });
        });

        const firstInput = tabInputs[0];
        firstInput.checked = true;
    }

    function handleInputChange(event: Event, type = 'click') {
        if (type === 'keydown') {
            const target = event.target as HTMLElement;

            if (target.previousElementSibling as HTMLInputElement) {
                const activeTabIndex = Array.from(tabInputs).indexOf(target.previousElementSibling as HTMLInputElement);
                activeTab = activeTabIndex;

                tabInputs[activeTab].checked = true;

                cards.forEach((item) => (item.style.display = 'none'));
                cards[activeTab].style.display = 'block';
            }
        } else {
            const target = event.target as HTMLInputElement;

            if (target.checked) {
                const activeTabIndex = Array.from(tabInputs).indexOf(target);
                activeTab = activeTabIndex;

                cards.forEach((item) => (item.style.display = 'none'));
                cards[activeTab].style.display = 'block';
            }
        }
    }

    if (cards.length > 0) {
        cards[activeTab].style.display = 'block';
    }
}