if (document.querySelector('.info-panel') != null) {
  const modal = document.querySelector('.modal-video') as HTMLDialogElement;
  const video = document.querySelector('.modal-video__video') as HTMLVideoElement;
  const playButton = document.querySelector('.info-panel__button') as HTMLElement;
  const videoPlaceholder = document.querySelector('.info-panel__right') as HTMLElement;

  playButton.addEventListener('click', () => {
    modal?.showModal();
  });

  videoPlaceholder.addEventListener('click', () => {
    modal?.showModal();
  });

  window.addEventListener('click', event => {
    if (event.target === modal) {
      closeVideoModal();
    }
  });

  const closeVideoModal = () => {
    modal.close();
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        animateStats();
        observer.unobserve(entry.target);
      }
    });
  }, { threshold: 0.5 });

  observer.observe(document.querySelector('.info-panel'));

  const animateStats = () => {
    const stats = document.querySelectorAll('.info-panel__stat-value');
    
    stats.forEach(stat => {
      const value = stat.textContent.trim();
      if (value && /^\d/.test(value)) {
        let start = 0;
        const end = parseInt(value.replace(/\D/g, ''));
        const suffix = value.match(/\D+$/)?.[0] ?? '';
        const increment = end < 1000 ? 1 : Math.ceil(end / (end < 10000 ? 100 : 50));
        const intervalTime = end < 10000 ? 18 : 1;

        const timer = setInterval(() => {
          start += increment;
          if (start <= end) {
            stat.textContent = start.toString() + suffix;
          } else {
            stat.textContent = value;
            clearInterval(timer);
          }
        }, intervalTime);
      } 
    });
  }  
}