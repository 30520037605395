// @ts-nocheck

const locationItem = document.querySelector(".location-item");

if (locationItem) {
    function initialize(): void {
        setTimeout(() => {
            const branch = document.querySelector('.location-item__left-branch-column') as HTMLElement;
            showMap(branch.dataset.lat, branch.dataset.lng, branch.dataset.content);
        }, 1000);
    }

    const showMap = (lat, lng, content) => {
        var styles: google.maps.MapTypeStyle[] = [{
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [{
                "saturation": 36
            }, {
                "color": "#333333"
            }, {
                "lightness": 40
            }]
        }, {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [{
                "visibility": "on"
            }, {
                "color": "#ffffff"
            }, {
                "lightness": 16
            }]
        }, {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#fefefe"
            }, {
                "lightness": 20
            }]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [{
                "color": "#fefefe"
            }, {
                "lightness": 17
            }, {
                "weight": 1.2
            }]
        }, {
            "featureType": "administrative",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [{
                "color": "#f5f5f5"
            }, {
                "lightness": 20
            }]
        }, {
            "featureType": "landscape",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
                "color": "#f5f5f5"
            }, {
                "lightness": 21
            }]
        }, {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
                "color": "#dedede"
            }, {
                "lightness": 21
            }]
        }, {
            "featureType": "road",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 17
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 29
            }, {
                "weight": 0.2
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 18
            }]
        }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 16
            }]
        }, {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [{
                "color": "#f2f2f2"
            }, {
                "lightness": 19
            }]
        }, {
            "featureType": "transit",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
                "color": "#e9e9e9"
            }, {
                "lightness": 17
            }]
        }, {
            "featureType": "water",
            "elementType": "labels.text",
            "stylers": [{
                "visibility": "off"
            }]
        }];
        window.map = new google.maps.Map(document.getElementById('map'), {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            clickableIcons: false,
            styles: styles,
            gestureHandling: 'greedy',
            draggable: false,
        });
        var bounds = new google.maps.LatLngBounds();
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: map,
            icon: {
                url: '/assets/img/fractal/map-pin.svg',
                size: new google.maps.Size(22, 40),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(11, 40),
            },
        });
        map.fitBounds(bounds);
        var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function (event) {
            this.setZoom(5);
            google.maps.event.removeListener(boundsListener);
        });
        bounds.extend(marker.position);
        var infowindow = new google.maps.InfoWindow({
            content: content
        });
        google.maps.event.addListenerOnce(map, 'idle', function () {
            if (window.innerWidth > 768) {
                if (document.documentElement.lang.toLowerCase() === "ar") {
                    map.panBy(390, -200);
                } else {
                    map.panBy(-390, -200);
                }
            } else {
                map.panBy(0, -200);
            }
            google.maps.event.trigger(this, 'resize');
            infowindow.open(map, marker);
        });
    }

    function loadScript() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBoxc6esb8-f4nRh-L3GpWwRvPgEez7UcE&callback=initialize';
        document.body.appendChild(script);
    }

    let activeBranch = document.querySelector('.location-item__left-branch-column') as any;
    let activeRegion = (document.querySelector('.location-item__left-region-dropdown') as HTMLSelectElement).value;

    const showBranches = (region: string) => {
        var branches = document.querySelectorAll(`.location-item__left-branch-column[data-region="${region}"]`);
        branches.forEach((branch: HTMLElement) => {
            branch.style.display = "block";
        });

        if (activeBranch) {
            activeBranch.classList.remove("active");
        }

        if (document.querySelector(".gm-style")) {
            activeBranch = branches[0];
            activeBranch.classList.add("active");
            showMap(activeBranch.dataset.lat, activeBranch.dataset.lng, activeBranch.dataset.content);
        }
    };

    const hideBranches = (region: string) => {
        var branches = document.querySelectorAll(`.location-item__left-branch-column[data-region="${region}"]`);
        branches.forEach((branch: HTMLElement) => {
            branch.style.display = "none";
        });
    }

    const onRegionChange = (e: Event) => {
        const targetElm = e.currentTarget as HTMLSelectElement;
        const region = targetElm.value;

        if (activeRegion !== region) {
            hideBranches(activeRegion);
            showBranches(region);
            activeRegion = region;
        }
    };

    const onBranchChange = (e: Event) => {
        const targetElm = e.currentTarget as HTMLElement;

        if (activeBranch) {
            activeBranch.classList.remove("active");
        }

        if (activeBranch !== targetElm) {
            targetElm.classList.add("active");
        } else {
            targetElm.classList.toggle("active");
        }

        activeBranch = targetElm.classList.contains("active") ? targetElm : null;
        showMap(targetElm.dataset.lat, targetElm.dataset.lng, targetElm.dataset.content)
    }

    const hookListeners = () => {
        document.querySelector(".location-item__left-region-dropdown").addEventListener("change", onRegionChange);

        document.querySelectorAll(".location-item__left-branch-column").forEach((item: Element) => {
            item.addEventListener("click", onBranchChange);
        });
    };

    document.addEventListener('DOMContentLoaded', () => {
        const firstRegion = (document.querySelector('.location-item__left-region-dropdown') as HTMLSelectElement).value;
        showBranches(firstRegion);
        document.querySelector(`.location-item__left-branch-column[data-region="${firstRegion}"]`).classList.add("active");

        hookListeners();
    });

    (window as any).initialize = initialize;

    window.onload = loadScript;
}