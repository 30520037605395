const navbar = document.querySelector('.navbar');

if (navbar) {
    const toggleDropdownVisibility = (dropdown: HTMLElement, isVisible: boolean) => {
        if (isVisible) {
            dropdown.classList.add('visible');
        } else {
            dropdown.classList.remove('visible');
        }
    };

    const hookListeners = () => {
        const dropdownTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('.navbar__dropdowns-item');

        dropdownTriggers.forEach((trigger: HTMLElement) => {
            const dropdown = trigger.querySelector('.navbar__dropdown') as HTMLElement;

            trigger.addEventListener('mouseenter', () => toggleDropdownVisibility(dropdown, true));
            trigger.addEventListener('mouseleave', () => toggleDropdownVisibility(dropdown, false));

            dropdown.addEventListener('mouseenter', () => toggleDropdownVisibility(dropdown, true));
            dropdown.addEventListener('mouseleave', () => toggleDropdownVisibility(dropdown, false));
        });
    };

    document.addEventListener('DOMContentLoaded', hookListeners);
}