import { DropdownElement } from "./header.types";

const header = document.querySelector(".header") as HTMLDivElement;

if (header) {
    const links = header.querySelectorAll(".header__link");

    let activeDropdown: DropdownElement | null = null;

    const showDropdown = (menu: DropdownElement) => {
        closeDropdown();

        menu.parent.classList.add("active");
        menu.menu.classList.add("active");

        activeDropdown = menu;
    }

    const closeDropdown = () => {
        if (activeDropdown) {
            activeDropdown.parent.classList.remove("active");
            activeDropdown.menu.classList.remove("active");

            activeDropdown = null;
        }
    }

    const onLinkPress = (e: Event) => {
        const targetElm = e.target as HTMLElement;

        if (activeDropdown && activeDropdown.parent === targetElm) return;

        const closestDropdown = targetElm.querySelector(".header__dropdown") as HTMLElement;

        let dropdown: DropdownElement = {
            parent: targetElm,
            menu: closestDropdown
        };

        showDropdown(dropdown);
    }

    const hookLinksListeners = () => {
        links.forEach((parentElm) => {
            parentElm.addEventListener("mouseenter", (e: Event) => {
                onLinkPress(e);
            });

            var sideMenuLogos = parentElm.querySelectorAll(".header__features-logo-container") as NodeListOf<HTMLElement>;
            if (sideMenuLogos.length > 0) {
                sideMenuLogos[0].classList.add("active");
                parentElm.querySelector(`.header__features-right[data-id='${sideMenuLogos[0].dataset.id}']`).classList.add("active");
            }

            sideMenuLogos.forEach((sideMenuLogo) => {
                sideMenuLogo.addEventListener("mouseenter", (e: Event) => {
                    const sideMenuLogo = e.target as HTMLElement;
                    const sideMenu = parentElm.querySelector(`.header__features-right[data-id='${sideMenuLogo.dataset.id}']`) as HTMLElement;

                    let selectedSideMenuLogo = parentElm.querySelector(".header__features-logo-container.active") as HTMLElement;
                    let selectedSideMenu = parentElm.querySelector(".header__features-right.active") as HTMLElement;

                    selectedSideMenuLogo?.classList.remove("active");
                    selectedSideMenu?.classList.remove("active");

                    sideMenuLogo.classList.add("active");
                    sideMenu.classList.add("active");
                })
            })
        });
    }

    const hookSearchListeners = () => {
        const searchIcon = header.querySelector(".header__search-icon") as HTMLLinkElement;
        const searchInput = header.querySelector(".search__input") as HTMLInputElement;
        const button = header.querySelector(".button") as HTMLInputElement;

        if (searchIcon) {
            const desktopLinks = header.querySelector(".header__links-desktop") as HTMLDivElement;

            searchIcon.addEventListener("click", (e: Event) => {
                e.preventDefault();
                desktopLinks.classList.toggle("header__links--search-active");
                searchIcon.classList.toggle("header__button--active");
            });
        }

        if (searchInput) {
            searchInput.addEventListener("input", () => {
                if (searchInput.value.trim() === "") {
                    button.disabled = true;
                } else {
                    button.disabled = false;
                }
            });
        }
    }

    const hookListeners = () => {
        header.addEventListener("mouseleave", closeDropdown);
        hookLinksListeners();
        hookSearchListeners();
    }

    document.addEventListener("DOMContentLoaded", hookListeners);
}