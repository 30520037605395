import { MobileMenuElement } from "./mobileMenu.types";

const mobileMenu = document.querySelector(".mobile-menu") as HTMLElement;

if (mobileMenu) {
    let activeContainer: HTMLElement | null = null;
    const navigationStack: MobileMenuElement[] = [];

    const onLanguagePress = (e: Event) => {
        const languageContainer = (e.target as HTMLElement).closest(".mobile-menu__language") as HTMLElement;

        const overlay = mobileMenu.querySelector(".mobile-menu__language-overlay") as HTMLElement;
        const chevron = languageContainer.querySelector(".zmdi") as HTMLElement;
        const logoLink = mobileMenu.querySelector(".mobile-menu__logo-link") as HTMLElement;

        logoLink.classList.toggle("mobile-menu__logo-link--no-press");
        overlay.classList.toggle("mobile-menu__language-overlay--active");

        if (overlay.classList.contains("mobile-menu__language-overlay--active")) {
            chevron.classList.remove("zmdi-chevron-down");
            chevron.classList.add("zmdi-chevron-up");

            document.documentElement.style.overflowY = "hidden";
        } else {
            chevron.classList.remove("zmdi-chevron-up");
            chevron.classList.add("zmdi-chevron-down");

            document.documentElement.style.overflowY = "auto";
        }
    }

    const onIconPress = (e: Event) => {
        const targetElm = e.target as HTMLElement;

        const icon = targetElm.closest(".mobile-menu__icon") as HTMLElement;
        const overlay = mobileMenu.querySelector(".mobile-menu__overlay") as HTMLElement;
        const languageOverlay = mobileMenu.querySelector(".mobile-menu__language-overlay") as HTMLElement;
        const language = mobileMenu.querySelector(".mobile-menu__language") as HTMLElement;
        const logoLink = mobileMenu.querySelector(".mobile-menu__logo-link") as HTMLElement;

        icon.classList.toggle("mobile-menu__icon--open");
        overlay.classList.toggle("mobile-menu__overlay--active");
        language.classList.toggle("hidden");
        logoLink.classList.toggle("mobile-menu__logo-link--no-press");
        languageOverlay.classList.remove("mobile-menu__language-overlay--active");

        if (activeContainer) {
            activeContainer.classList.remove("mobile-menu__items-container--active");
        }

        activeContainer = mobileMenu.querySelector(".mobile-menu__items-container") as HTMLElement;
        activeContainer.classList.add("mobile-menu__items-container--active");

        if (!overlay.classList.contains("mobile-menu__overlay--active")) {
            document.documentElement.style.overflowY = "auto";

            navigationStack.length = 0;
            updateHeaderUI();
        } else {
            document.documentElement.style.overflowY = "hidden";
        }
    }

    const updateHeaderUI = () => {
        const logo = mobileMenu.querySelector('.mobile-menu__logo');
        const textContainer = mobileMenu.querySelector('.mobile-menu__header-text-container');

        if (navigationStack.length > 0) {
            logo.classList.add('hidden');
            textContainer.classList.remove('hidden');
            var currentStack = navigationStack[navigationStack.length - 1];

            const imageElement = textContainer.querySelector("#mobile-menu__header-image") as HTMLImageElement;
            const textElement = textContainer.querySelector("#mobile-menu__header-text") as HTMLElement;

            if (currentStack.image) {
                imageElement.src = currentStack.image;
                imageElement.classList.remove("hidden");
                textElement.classList.add("hidden");
            } else if (currentStack.label) {
                textElement.innerText = currentStack.label;
                imageElement.classList.add("hidden");
                textElement.classList.remove("hidden");
            }
        } else {
            logo.classList.remove('hidden');
            textContainer.classList.add('hidden');
        }
    }

    const onItemPress = (e: Event) => {
        const targetElm = (e.target as HTMLElement).closest(".mobile-menu__item") as HTMLElement;

        if (!targetElm.querySelector(".zmdi")) return;

        let currentStack: MobileMenuElement = {
            menu: activeContainer
        }

        activeContainer.classList.remove("mobile-menu__items-container--active");

        if (targetElm.classList.contains("mobile-menu__item--feature-list")) {
            var mappedContainer =
                mobileMenu
                    .querySelector(`.mobile-menu__items-container--feature-list[data-id="${targetElm.dataset.id}"][data-parentid="${targetElm.dataset.parentid}"]`) as HTMLElement;

            currentStack.image = (targetElm.querySelector(".mobile-menu__item-image") as HTMLImageElement).src;
            activeContainer = mappedContainer;
        } else {
            currentStack.label = targetElm.querySelector("span").innerText;
            activeContainer = mobileMenu.querySelector(`.mobile-menu__items-container[data-id="${targetElm.dataset.id}"]`) as HTMLElement;
        }

        activeContainer.classList.add("mobile-menu__items-container--active");

        navigationStack.push(currentStack);
        updateHeaderUI();
    }

    const onBackPress = (e: Event) => {
        if (navigationStack.length > 0) {
            const previousStack = navigationStack.pop();
            activeContainer.classList.remove("mobile-menu__items-container--active");
            activeContainer = previousStack.menu;
            activeContainer.classList.add("mobile-menu__items-container--active");
            updateHeaderUI();
        }
    };

    const hookListeners = () => {
        const icon = mobileMenu.querySelector(".mobile-menu__icon") as HTMLElement;
        const items = mobileMenu.querySelectorAll(".mobile-menu__item") as NodeListOf<HTMLElement>;
        const backContainer = mobileMenu.querySelector(".mobile-menu__header-left") as HTMLElement;
        const languageContainer = mobileMenu.querySelector(".mobile-menu__language") as HTMLElement;

        icon.addEventListener("click", onIconPress)

        items.forEach(item => {
            item.addEventListener("click", onItemPress);
        })

        languageContainer.addEventListener("click", onLanguagePress);

        backContainer.addEventListener("click", onBackPress);
    }

    document.addEventListener("DOMContentLoaded", hookListeners);
}